/* eslint-disable */
import { RouteRecordRaw } from "vue-router";
import SideMenuLayout from "@/layouts/SideMenuLayout.vue";
import { DotpadList } from "sdk/DotpadList";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/update/firmware/connect",
    name: "Dot Pad Connect",
    component: () => import(/* webpackChunkName: "home" */ "../views/update/firmware/ConnectView.vue"),
    meta: { layout: SideMenuLayout },
  },
  {
    path: "/update/firmware/latest",
    name: "Dot Pad Firmware Update",
    component: () => import(/* webpackChunkName: "home" */ "../views/update/firmware/LatestView.vue"),
    meta: { layout: SideMenuLayout },
    beforeEnter: (to, from, next) => {
      DotpadList.getConnectCount() != 0 ? next() : next("/update/firmware/connect");
    }
  },
];

export default routes;
