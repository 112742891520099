/*
 * =====================================================================
 * 작 업 명  : 전역함수
 * 파 일 명  : global.js
 * 변경이력
 * 2022-06-17 / 정태화 / 최초작성
 * 2023-02-10 / 이성준 / 업체번호 전역변수 추가
 * 2023-06-29 / 배윤석 / 닷프레임워크 VUEX => 피니아 변경에 따른 호출 로직 수정
 * =====================================================================
 */
import { $session, $router, $site, $menu } from "framework";
import { useSiteStore } from "@/store/site";

export default {
  // 통합인증 및 메뉴/권한 설정
  setSession() {
    // (1) 통합인증 연동 설정
    let siteNo = process.env.VUE_APP_SITE_NO;
    let ssoDomain = process.env.VUE_APP_SSO_DOMAIN;
    let callbackUrl = process.env.VUE_APP_CALLBACK_URL;

    $session.setSiteNo(siteNo);
    $session.setSsoDomain(ssoDomain);
    $session.setCallbackUrl(callbackUrl);

    const menuStore = $menu();
    const siteStore = useSiteStore();

    // (2) 업체번호 설정 및 메뉴/권한 연동
    if (process.env.VUE_APP_IS_ELECTRON == "true") {
      const compNo = "C220512001"; // 업체번호(주식회사닷) 설정
      $session.setCompNo(compNo);
      siteStore.compNo = compNo; // 메뉴/권한 연동
      menuStore.bindMenuList();
    } else {
      // 도메인에서 업체번호 조회
      let domain = $router.getDomain();
      $site.setCompNoBydomain(domain, function (compNo) {
        // compNo = "C220121001"; // 테스트 임시
        $session.setCompNo(compNo); // 업체번호 설정
        siteStore.compNo = compNo; // 메뉴/권한 연동
        menuStore.bindMenuList();
      });
    }
  },
  isLogin() {
    return $session.isLogin();
  },
};
