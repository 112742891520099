/* eslint-disable */
import { RouteRecordRaw } from "vue-router";
import SideMenuLayout from "@/layouts/SideMenuLayout.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/admin/firmware/list",
    name: "Dot Pad Firmware List",
    component: () =>
      import(
        /* webpackChunkName: "home" */ "../views/admin/firmware/ListView.vue"
      ),
    meta: { layout: SideMenuLayout },
  },
];

export default routes;
