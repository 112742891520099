import { RouteRecordRaw } from "vue-router";
import BlankLayout from "@/layouts/BlankLayout.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/login/Login.vue"),
    meta: { layout: BlankLayout },
  },
  {
    path: "/login-callback",
    name: "login-callback",
    component: () =>
      import(
        /* webpackChunkName: "login-callback" */ "../views/login/LoginCallback.vue"
      ),
    meta: { layout: BlankLayout },
  },
];

export default routes;
