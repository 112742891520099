let DOT_PAD_LIST = [];

const DotpadList = {
  // dotpad 인스턴스 추가
  addDotPad(addDotpad) {
    // 존재하지 않은 경우 추가
    let addDeiceName = addDotpad.connectedDeviceName;
    let exist = false;
    for (let i in DOT_PAD_LIST) {
      let dotpad = DOT_PAD_LIST[i];
      if (addDeiceName == dotpad.connectedDeviceName) {
        exist = true;
        break;
      }
    }
    if (!exist) {
      DOT_PAD_LIST.push(addDotpad);
    }
  },
  // dotpad 목록 조회
  getList() {
    return DOT_PAD_LIST;
  },
  // dotpad JSON 목록 조회
  getJsonList() {
    let result = [];
    for (let i in DOT_PAD_LIST) {
      let dotpad = DOT_PAD_LIST[i];
      let json = {
        deviceName: dotpad.connectedDeviceName,
      };
      result.push(json);
    }
    return result;
  },
  // 닷패드 연결된 기기 갯수
  getConnectCount() {
    return DOT_PAD_LIST.length;
  },
  // 디바이스명으로 닷패드목록에서 조회
  getDotPad(deviceName) {
    let result = null;
    for (let i in DOT_PAD_LIST) {
      let dotpad = DOT_PAD_LIST[i];
      if (deviceName == dotpad.connectedDeviceName) {
        result = DOT_PAD_LIST[i];
        break;
      }
    }
    return result;
  },
  // 디바이스명으로 닷패드목록에서 삭제
  removeDotPad(deviceName) {
    let index = 0;
    for (let i in DOT_PAD_LIST) {
      let dotpad = DOT_PAD_LIST[i];
      if (deviceName == dotpad.connectedDeviceName) {
        index = i;
        break;
      } else {
        index = -1;
      }
    }
    if (index != -1) {
      DOT_PAD_LIST.splice(index, 1);
    }
  },
};

export { DotpadList, DOT_PAD_LIST };
